import Cookies from "js-cookie";

// helpers
import users from "@/api/query/users";
import { EOrganizationAccessLevel, IUserMe } from "@/types/api";

export type TProfilePermissions = {
  isInternalOrganization: boolean;
  isExternalOrganization: boolean;
} & IUserMe["permissions"];

export function useProfile() {
  const { data, ...rest } = users.useMe({
    config: { enabled: !!Cookies.get("accessToken") }
  });

  return {
    data: data,
    permissions: {
      ...(data?.permissions || {}),
      isInternalOrganization: data?.organization?.accessLevel === EOrganizationAccessLevel.INTERNAL,
      isExternalOrganization: data?.organization?.accessLevel === EOrganizationAccessLevel.EXTERNAL,
    } as TProfilePermissions,
    ...rest
  };
}
