export enum EOrganizationAccessLevel {
  INTERNAL = "internal",
  EXTERNAL = "external",
}

export interface IOrganizationCreate {
  name: string;
  prefix: string | null;
}


export interface IOrganization extends IOrganizationCreate {
  id: number;
  accessLevel: EOrganizationAccessLevel;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  usersNumber: number;
  tendersNumber: number;
}
