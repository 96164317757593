import axiosLib from "axios";
import Cookies from "js-cookie";

// helpers
import API_ENDPOINTS from "./aipEndpoints.constants";
import { ROUTES } from "@/pages/Router/routes.constants";

const apiUrl = process.env.REACT_APP_API_URL;

export const baseURL = apiUrl as string;

export const axios = axiosLib.create({
  baseURL,
  headers: { "Content-Type": "application/json" },
});

const refetchToken = async () => {
  const refreshToken = Cookies.get("refreshToken");

  if (!refreshToken) {
    window.location.href = ROUTES.AUTH.SIGN_IN;

    return new Error("No refresh token");
  }

  try {
    const response = await axios.post<unknown, { tokens: { accessToken: string; refreshToken: string } }>(API_ENDPOINTS.AUTH.REFRESH, { refreshToken: Cookies.get("refreshToken") });

    Cookies.set("accessToken", response.tokens.accessToken);
    Cookies.set("refreshToken", response.tokens.refreshToken);

    return response.tokens.accessToken;
  } catch (error) {
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");

    window.location.href = ROUTES.AUTH.SIGN_IN;

    throw error;
  }
};

axios.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get("accessToken");

    if (accessToken && config.url && !config.url.includes(API_ENDPOINTS.AUTH.ROOT)) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      ![API_ENDPOINTS.AUTH.LOGIN, API_ENDPOINTS.AUTH.REFRESH].includes(error.config.url) &&
      !error.config.__isRetryRequest
    ) {
      error.config.__isRetryRequest = true;
      const token = await refetchToken();

      if (token) {
        return axios(error.config);
      }
    }

    return Promise.reject(error.response);
  },
);
