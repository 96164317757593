import { lazy } from "react";
import { RouteObject } from "react-router-dom";

// pages
const Reports = lazy(() => import("@/pages/reports"));
const SingleReport = lazy(() => import("@/pages/reports/SingleReport"));

// helpers
import { EOrganizationAccessLevel } from "@/types/api";
import { renderWithSuspense } from "../utils";
import { ROUTES } from "../routes.constants";


export const REPORTS_ROUTES: RouteObject[] = [
  {
    path: ROUTES.REPORTS.ROOT,
    Component: renderWithSuspense(Reports, {
      permissions: { organization: [EOrganizationAccessLevel.INTERNAL] }
    }),
  },
  {
    path: ROUTES.REPORTS.SINGLE_REPORT,
    Component: renderWithSuspense(SingleReport, {
      permissions: { organization: [EOrganizationAccessLevel.INTERNAL] }
    }),
  }
];
