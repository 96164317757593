import { useEffect } from "react";
import { AxiosRequestConfig } from "axios";
import {
  useQuery,
  useInfiniteQuery,
  QueryKey,
  GetNextPageParamFunction,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult
} from "@tanstack/react-query";
import get from "lodash.get";

// helpers
import queryString from "@/lib/queryString";
import { axios } from "@/api/axios";
import removeEmptyParams from "./removeEmptyParams";
import getQueryClient from "./getQueryClient";
import { mutationHooksFactory } from "./mutationHooksFactory";

// interfaces
import { IUseQueryResult, IUseQuery, IUseWebsocket } from "@/types/utils/react-query";
import { IPaginated } from "@/types/api";

export const queryClient = getQueryClient();

type FetcherParams = {
  queryKey: QueryKey;
  page?: number;
  config?: Record<string, unknown>;
};

export async function fetcher({ queryKey, page, config = {} }: FetcherParams) {
  const [url, params] = queryKey as [string, Record<string, unknown>];
  const res = await axios.get(url, {
    params: { ...params, page: page || params?.page },
    ...config
  });
  
  return res || {};
}

function mutationFnPost(url?: string | null, params?: Record<string, unknown>) {
  return function mutationFn(data: unknown, config?: AxiosRequestConfig) {
    return axios.post(url as string, data, { params: { ...(params || {}), ...(config || {}) } });
  };
}

function mutationFnPatch(url?: string | null, params?: Record<string, unknown>) {
  return function mutationFn(data: unknown, config?: AxiosRequestConfig) {
    return axios.patch(url as string, data, { params: { ...(params || {}), ...(config || {}) } });
  };
}

function mutationFnPut(url?: string | null, params?: Record<string, unknown>) {
  return function mutationFn(data: unknown, config?: AxiosRequestConfig) {
    return axios.put(url as string, data, { params: { ...(params || {}) }, ...(config || {}) });
  };
}

function mutationFnDelete(url?: string | null, params?: Record<string, unknown>) {
  return function mutationFn(id: unknown, config?: AxiosRequestConfig) {
    return axios.delete(`${url}/${id}`, { params: { ...(params || {}), ...(config || {}) } });
  };
}

export function useWebsocket(args: IUseWebsocket) {
  const { socketUrl, params, callbacks, config } = args;

  const { data } = useQuery({
    queryKey: [socketUrl, params],
    queryFn: ({ queryKey }: { queryKey: QueryKey }) => {
      const [url, keyParams] = queryKey as [string, Record<string, unknown>];
      const connection = new WebSocket(
        `${process.env.NEXT_PUBLIC_SOCKET_URL}/${url}?${queryString.stringify(keyParams)}`
      );

      if (callbacks) {
        Object.keys(callbacks).forEach((key) => {
          connection.addEventListener(key, get(callbacks, key));
        });
      }

      return connection;
    },
    ...config
  });

  const socketConnection = data as WebSocket;

  // effects
  useEffect(() => {
    return () => {
      if (socketConnection && socketConnection.readyState === 1) {
        socketConnection.close();
      }
    };
  }, [socketConnection]);

  return socketConnection as WebSocket;
}

export function useFetch<T = unknown>(
  url: string | null,
  params?: Record<string, unknown>,
  config?: IUseQuery
) {
  const queryKey = [url, removeEmptyParams(params)].filter(Boolean);

  const result = useQuery(
    {
      queryKey,
      queryFn: (args) => fetcher({ ...args, config: config?.axiosConfig }),
      enabled: !!config?.enabled || !!url,
      ...(config || {})
    },
    queryClient
  ) as IUseQueryResult<T>;

  return {
    ...result,
    remove: () => queryClient.removeQueries({ queryKey })
  };
}

export function useInfiniteFetch<T = unknown>(
  url: string | null,
  params?: Record<string, unknown>,
  config?: IUseQuery
) {
  const isEnabled = !!config?.enabled || !!url;
  const queryUrl = [url, removeEmptyParams(params)].filter(Boolean);

  const data = useInfiniteQuery({
    queryKey: queryUrl,
    queryFn: ({ queryKey, pageParam = 0 }: { queryKey: QueryKey; pageParam?: number }) => fetcher({
      queryKey,
      page: pageParam
    }),
    getNextPageParam: ((_lastPage: never, pages: IPaginated<T>[]) => {
      const { hasNext, page } = pages[pages.length - 1] || ({} as IPaginated<T>);

      if (hasNext) {
        return `${page + 1}`;
      }
    }) as unknown as GetNextPageParamFunction<never, unknown>,
    enabled: isEnabled,
    ...(config || {})
  } as unknown as UseInfiniteQueryOptions<T>) as UseInfiniteQueryResult;

  const pages = get(data, "data.pages", []) as IPaginated<T>[];
  const lastPage = pages ? pages[pages.length - 1] : {};

  const dataToReturn = {
    ...lastPage,
    entities: pages.map((item) => item.entities).flat() || ([] as T[])
  } as IPaginated<T>;

  const remove = () => {
    queryClient.removeQueries({ queryKey: queryUrl });
  };

  return { ...data, data: pages ? dataToReturn : pages, remove };
}

export const usePost = mutationHooksFactory(mutationFnPost);
export const usePatch = mutationHooksFactory(mutationFnPatch);
export const usePut = mutationHooksFactory(mutationFnPut);
export const useDelete = mutationHooksFactory(mutationFnDelete);
