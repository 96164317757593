import { Component, ReactNode } from "react";
import { FileWarning } from "lucide-react";

// components
import { Button } from "@/components/ui/button";

interface ErrorBoundaryProps {
  children: ReactNode;
  fallback?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error): void {
    if (error.stack?.includes("ChunkLoadError") && !sessionStorage.getItem("ChunkLoadErrorReloaded")) {
      window.location.reload();

      sessionStorage.setItem("ChunkLoadErrorReloaded", "true");
    }
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return this.props.fallback || (
        <div className="p-8 flex flex-col items-center justify-center gap-4 border-slate-200 border rounded-lg w-full">
          <span className="bg-slate-200 border-slate-50 border-8 rounded-full w-14 h-14 flex items-center justify-center">
            <FileWarning className="text-slate-400" />
          </span>
          <div className="flex flex-col gap-4 items-center">
            <h1 className="text-2xl text-center">
              Something went wrong
            </h1>
            <div className="flex gap-4">
              <a href="/">
                <Button variant="outline">
                  Go to home
                </Button>
              </a>
              <Button onClick={() => window.location.reload()}>
                Try again
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
