export enum ETemplateTaskCategory {
  SCOPE_OF_WORK = "scope-of-work",
  SCOPE_SPECIFIC_TASK = "store-specific",
  SUBCONTRACTOR_PRICING = "subcontractor-pricing"
}

export interface ITemplateTask {
  description: string;
  position: number;
  category: ETemplateTaskCategory;
}

export interface ITemplateTaskUpdate extends Omit<ITemplateTask, "id"> {
  id?: number;
  customId: string | number;
  budget?: string | number;
  readonly?: boolean;
  isNew?: boolean;
}

export interface ITemplateCreate {
  name: string,
  tasks: ITemplateTaskUpdate[];
}

export interface ITemplate extends ITemplateCreate {
  id: number;
  position?: number;
}
