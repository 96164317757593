import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// components
import Router from "@/pages/Router";
import ErrorBoundary from "@/containers/ErrorBoundary";

// helpers
import { queryClient } from "@/api/query/utils";


function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <Router />
      </ErrorBoundary>
      { process.env.NODE_ENV === "development" && <ReactQueryDevtools initialIsOpen={false} /> }
    </QueryClientProvider>
  );
}

export default App;
