import { lazy } from "react";
import { RouteObject } from "react-router-dom";

// pages
const TemplatesList = lazy(() => import("@/pages/templates/TemplatesList"));
const AddEditTemplate = lazy(() => import("@/pages/templates/AddEditTemplate"));

// helpers
import { renderWithSuspense } from "../utils";
import { ROUTES } from "../routes.constants";
import { EOrganizationAccessLevel } from "@/types/api";


export const TEMPLATES_ROUTES: RouteObject[] = [
  {
    path: ROUTES.TEMPLATES.ROOT,
    Component: renderWithSuspense(TemplatesList, {
      permissions: { organization: [EOrganizationAccessLevel.INTERNAL] }
    })
  },
  {
    path: ROUTES.TEMPLATES.CREATE,
    Component: renderWithSuspense(AddEditTemplate, {
      permissions: {
        organization: [EOrganizationAccessLevel.INTERNAL],
        profile: ["canManageTemplates"]
      }
    })
  },
  {
    path: ROUTES.TEMPLATES.SINGLE_TEMPLATE,
    Component: renderWithSuspense(AddEditTemplate, {
      permissions: {
        organization: [EOrganizationAccessLevel.INTERNAL],
      }
    })
  }
];
