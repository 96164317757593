/* eslint-disable react/display-name */
import React, { Suspense } from "react";
import Cookies from "js-cookie";
import { redirect } from "react-router-dom";
import get from "lodash/get";

// pages
import ErrorBoundary from "@/containers/ErrorBoundary";
const NotFoundPage = React.lazy(() => import("../NotFoundPage"));

// constants
import { ROUTES } from "./routes.constants";

// helpers
import { useProfile, TProfilePermissions } from "@/hooks/useProfile";
import { EOrganizationAccessLevel } from "@/types/api";


type TPermissions = {
  organization: EOrganizationAccessLevel[];
  profile?: (keyof TProfilePermissions)[];
};

export const privateRouteLoader = async () => {
  const accessToken = Cookies.get("accessToken");

  if (!accessToken) {
    return redirect(ROUTES.AUTH.SIGN_IN);
  }

  return null;
}

export const renderWithSuspense = (
  Component: React.LazyExoticComponent<({ children }: { children?: React.ReactNode, }) => React.JSX.Element>,
  config?: {
    permissions?: TPermissions,
    props?: Record<string, unknown>
  },
) => () => {
  const { organization, profile = [] } = config?.permissions || {};
  const { data, permissions: profilePermissions } = useProfile();

  const showNotFound = [
    data && organization && !organization.includes(data?.organization.accessLevel),
    profile.some(key => !get(profilePermissions, key))
  ].some(Boolean);

  if (showNotFound) {
    return (
      <ErrorBoundary>
        <Suspense fallback="...">
          <NotFoundPage/>
        </Suspense>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <Suspense fallback="...">
        <Component { ...(config?.props || {}) }/>
      </Suspense>
    </ErrorBoundary>
  );
};
