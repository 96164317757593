import { lazy } from "react";
import { RouteObject } from "react-router-dom";

// layouts
const ProfilePageLayout = lazy(() => import("@/layouts/ProfilePageLayout"));

// pages
const PersonalDetails = lazy(() => import("@/pages/profile/PersonalDetails"));
const ChangePassword = lazy(() => import("@/pages/profile/ChangePassword"));
const NotificationsSettings = lazy(() => import("@/pages/profile/NotificationsSettings"));

// helpers
import { renderWithSuspense } from "../utils";
import { ROUTES } from "../routes.constants";


export const PROFILE_ROUTES: RouteObject[] = [
  {
    path: ROUTES.PROFILE.PROFILE_DETAILS,
    Component: renderWithSuspense(ProfilePageLayout),
    children: [
      {
        path: "",
        Component: renderWithSuspense(PersonalDetails)
      },
      {
        path: ROUTES.PROFILE.CHANGE_PASSWORD,
        Component: renderWithSuspense(ChangePassword)
      },
      {
        path: ROUTES.PROFILE.NOTIFICATIONS,
        Component: renderWithSuspense(NotificationsSettings)
      }
    ]
  },
];
