import { lazy } from "react";
import { RouteObject } from "react-router-dom";

// pages
const RFPManagement = lazy(() => import("@/pages/rfp-management/RFPManagement"));
const RFPCreate = lazy(() => import("@/pages/rfp-management/RFPCreate"));
const SingleRFP = lazy(() => import("@/pages/rfp-management/SingleRFP"));
const RFPProjectOutline = lazy(() => import("@/containers/rfp/RFPProjectOutline"));
const StandardsAndProcedures = lazy(() => import("@/containers/StandardsAndProcedures"));
const InformationOutline = lazy(() => import("@/containers/InformationOutline"));
const SingleRFPInitiative = lazy(() => import("@/pages/rfp-management/SingleRFP/pages/SingleRFPInitiative"));
const SingleRFPContractors = lazy(() => import("@/pages/rfp-management/SingleRFP/pages/SingleRFPContractors"));
const SingleRFPCreateReport = lazy(() => import("@/pages/rfp-management/SingleRFP/pages/CreateRFPReport"));
const RFPSignTender = lazy(() => import("@/pages/rfp-management/RFPSignTender"));
const ReviewTender = lazy(() => import("@/pages/rfp-management/ReviewTender"));
const RFPScheduleOfExecution = lazy(() => import("@/containers/rfp/RFPScheduleOfExecution"));
const RFPSignTenderProjectFees = lazy(() => import("@/containers/rfp/RFPSignTenderProjectFees"));
const RFPSignTenderDocuments = lazy(() => import("@/containers/rfp/RFPSignTenderDocuments"));
const RFPSignTenderBudgetSummary = lazy(() => import("@/containers/rfp/RFPSignTenderBudgetSummary"));
const RFPSignTenderInitiatives = lazy(() => import("@/containers/rfp/RFPSignTenderInitiatives"));
const RFPSignTenderSteelCleanUp = lazy(() => import("@/containers/rfp/RFPSignTenderSteelCleanUp"));
const RFPSignTenderChangeOrders = lazy(() => import("@/containers/rfp/RFPSignTenderChangeOrders"));
const Comments = lazy(() => import("@/containers/Comments"));

// helpers
import { renderWithSuspense } from "../utils";
import { ROUTES } from "../routes.constants";
import { EOrganizationAccessLevel } from "@/types/api";


export const RFP_MANAGEMENT_ROUTES: RouteObject[] = [
  {
    path: ROUTES.RFP_MANAGEMENT.ROOT,
    Component: renderWithSuspense(RFPManagement)
  },
  {
    path: ROUTES.RFP_MANAGEMENT.RFP_CREATE,
    Component: renderWithSuspense(RFPCreate, { permissions: { organization: [EOrganizationAccessLevel.INTERNAL] } })
  },
  {
    path: ROUTES.RFP_MANAGEMENT.SINGLE_RFP.ROOT,
    Component: renderWithSuspense(SingleRFP),
    children: [
      {
        path: ROUTES.RFP_MANAGEMENT.SINGLE_RFP.ROOT,
        Component: renderWithSuspense(RFPProjectOutline)
      },
      {
        path: ROUTES.RFP_MANAGEMENT.SINGLE_RFP.STANDARDS_PROCEDURES,
        Component: renderWithSuspense(StandardsAndProcedures)
      },
      {
        path: ROUTES.RFP_MANAGEMENT.SINGLE_RFP.INFORMATION_OUTLINE,
        Component: renderWithSuspense(InformationOutline)
      },
      {
        path: ROUTES.RFP_MANAGEMENT.SINGLE_RFP.INITIATIVES,
        Component: renderWithSuspense(SingleRFPInitiative)
      },
      {
        path: ROUTES.RFP_MANAGEMENT.SINGLE_RFP.INVITED_CONTRACTORS,
        Component: renderWithSuspense(SingleRFPContractors)
      },
      {
        path: ROUTES.RFP_MANAGEMENT.SINGLE_RFP.COMMENTS,
        Component: renderWithSuspense(Comments)
      },
    ],
  },
  {
    path: ROUTES.RFP_MANAGEMENT.SINGLE_RFP.CREATE_REPORT,
    Component: renderWithSuspense(SingleRFPCreateReport, {
      permissions: { organization: [EOrganizationAccessLevel.INTERNAL] }
    })
  },
  {
    path: ROUTES.RFP_MANAGEMENT.SINGLE_RFP.SUBMIT_TENDER,
    Component: renderWithSuspense(RFPSignTender)
  },
  {
    path: ROUTES.RFP_MANAGEMENT.RFP_TENDER.ROOT,
    Component: renderWithSuspense(ReviewTender),
    children: [
      {
        path: ROUTES.RFP_MANAGEMENT.RFP_TENDER.ROOT,
        Component: renderWithSuspense(RFPProjectOutline, { props: { readonly: true } })
      },
      {
        path: ROUTES.RFP_MANAGEMENT.RFP_TENDER.STANDARDS_PROCEDURES,
        Component: renderWithSuspense(StandardsAndProcedures)
      },
      {
        path: ROUTES.RFP_MANAGEMENT.RFP_TENDER.INITIATIVES,
        Component: renderWithSuspense(RFPSignTenderInitiatives, { props: { withNavigation: false, readonly: true } })
      },
      {
        path: ROUTES.RFP_MANAGEMENT.RFP_TENDER.STEEL_CLEAN_UP,
        Component: renderWithSuspense(RFPSignTenderSteelCleanUp, { props: { readonly: true } })
      },
      {
        path: ROUTES.RFP_MANAGEMENT.RFP_TENDER.PROJECT_FEES,
        Component: renderWithSuspense(RFPSignTenderProjectFees, { props: { readonly: true } })
      },
      {
        path: ROUTES.RFP_MANAGEMENT.RFP_TENDER.DOCUMENTS,
        Component: renderWithSuspense(RFPSignTenderDocuments, { props: { readonly: true } })
      },
      {
        path: ROUTES.RFP_MANAGEMENT.RFP_TENDER.BUDGET_SUMMARY,
        Component: renderWithSuspense(RFPSignTenderBudgetSummary, { props: { readonly: true } })
      },
      {
        path: ROUTES.RFP_MANAGEMENT.RFP_TENDER.SCHEDULE_OF_EXECUTION,
        Component: renderWithSuspense(RFPScheduleOfExecution)
      },
      {
        path: ROUTES.RFP_MANAGEMENT.RFP_TENDER.CHANGE_ORDERS,
        Component: renderWithSuspense(RFPSignTenderChangeOrders)
      },
      {
        path: ROUTES.RFP_MANAGEMENT.RFP_TENDER.COMMENTS,
        Component: renderWithSuspense(Comments)
      }
    ],
  }
];
