import * as React from "react"
import { Link } from "react-router-dom"
import { Slot } from "@radix-ui/react-slot"
import { LoaderCircle } from "lucide-react";
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"

const buttonVariants = cva(
  "inline-flex gap-2 relative items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 min-w-8 w-max ",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        dark: "bg-slate-900 text-slate-100 hover:bg-slate-800",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline text-slate-900",
      },
      size: {
        default: "h-10 px-4 py-2",
        xs: "h-8 rounded-md px-3",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-8 w-8",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  to?: string;
  isLoading?: boolean;
  danger?: boolean;
  primary?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {
  const {
    className,
    variant,
    size,
    asChild = false,
    to,
    isLoading,
    children,
    danger,
    primary,
    type = "button",
    ...rest
  } = props;

  const Comp = asChild ? Slot : "button";
  const isDisabled = isLoading || props.disabled;

  const renderButton = () => (
    <Comp
      className={cn(buttonVariants({ variant, size, className, }), danger && "text-destructive", primary && "text-primary border-primary hover:text-primary")}
      ref={ref}
      disabled={isDisabled}
      data-disabled={`${isDisabled}`}
      onClick={isDisabled ? (e) => e.preventDefault() : props.onClick}
      type={type}
      data-testid="hdep-button"
      {...rest}
    >
      {isLoading
        ? (
          <div data-testid="hdep-button-loading-indicator" className="absolute w-full h-full flex items-center justify-center bg-slate-100 bg-opacity-70 z-10">
            <LoaderCircle size={24} className="mr-2 animate-spin text-slate-900" />
          </div>
        )
        : null
      }
      {children}
    </Comp>
  )

  if (to) {
    return (
      <Link data-testid="hdep-button-link" to={to} className={cn(buttonVariants({ variant, size, className }), "p-0")}>
        {renderButton()}
      </Link>
    )
  }
    
  return renderButton();
}
)
Button.displayName = "Button"

export { Button, buttonVariants }
