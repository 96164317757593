import { useParams } from "react-router-dom";

// helpers
import API_ENDPOINTS from "../aipEndpoints.constants";
import { useFetch, usePatch, usePut } from "./utils";
import { useQueryParams } from "@/hooks/useQueryParams";

// types
import { IUser, IPaginated, IUserMe, IEmailNotificationSettings } from "@/types/api";
import { IUseQueryArgs, IUseMutation } from "@/types/utils/react-query";

export default {
  useMe(args?: IUseQueryArgs) {
    const { params, config } = args || {};

    return useFetch<IUserMe>(API_ENDPOINTS.USERS.ME, params, config);
  },
  usePatchMe(args?: IUseMutation) {
    const { params, config } = args || {};

    return usePatch(API_ENDPOINTS.USERS.ME, params, config);
  },
  useUsers(args?: IUseQueryArgs) {
    const params = useParams();

    const [{
      page = 1,
      perPage = 10,
      search,
      sortField = null,
      sortOrder = null,
      organizationId = null,
    }] = useQueryParams();

    const { config } = args || {};

    return useFetch<IPaginated<IUser>>(
      API_ENDPOINTS.USERS.ROOT,
      {
        page,
        perPage,
        search,
        sortField,
        sortOrder,
        organizationId: params.id || organizationId,
      },
      config
    );
  },

  useNotificationSettings(args?: IUseQueryArgs) {
    const { config } = args || {};

    return useFetch<IEmailNotificationSettings>(API_ENDPOINTS.USERS.NOTIFICATION_SETTINGS, {}, config);
  },

  usePatchNotificationSettings(args?: IUseMutation<Omit<IEmailNotificationSettings, "userId">, IEmailNotificationSettings>) {
    const { params, config } = args || {};

    return usePut<Omit<IEmailNotificationSettings, "userId">, IEmailNotificationSettings>(API_ENDPOINTS.USERS.NOTIFICATION_SETTINGS, params, config);
  }
};
