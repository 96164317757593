import { lazy } from "react";
import { RouteObject } from "react-router-dom";

// pages
const UsersList = lazy(() => import("@/pages/user-management/UsersList"));
const Contractors = lazy(() => import("@/pages/user-management/contractors/Contractors"));
const SingleContractor = lazy(() => import("@/pages/user-management/contractors/SingleContractor"));
const SingleContractorUsers = lazy(() => import("@/pages/user-management/contractors/SingleContractor/SingleContractorUsers"));
const SingleContractorDetails = lazy(() => import("@/pages/user-management/contractors/SingleContractor/SingleContractorDetails"));
const SingleContractorTenders = lazy(() => import("@/pages/user-management/contractors/SingleContractor/SingleContractorTenders"));

// helpers
import { renderWithSuspense } from "../utils";
import { ROUTES } from "../routes.constants";
import { EOrganizationAccessLevel } from "@/types/api";

export const USER_MANAGEMENT_ROUTES: RouteObject[] = [
  {
    path: ROUTES.USER_MANAGEMENT.ROOT,
    Component: renderWithSuspense(UsersList),
  },
  {
    path: ROUTES.USER_MANAGEMENT.CONTRACTORS,
    Component: renderWithSuspense(Contractors, {
      permissions: { organization: [EOrganizationAccessLevel.INTERNAL] }
    }),
  },
  {
    path: ROUTES.USER_MANAGEMENT.SINGLE_CONTRACTOR,
    Component: renderWithSuspense(SingleContractor, {
      permissions: { organization: [EOrganizationAccessLevel.INTERNAL] }
    }),
    children: [
      {
        path: ROUTES.USER_MANAGEMENT.SINGLE_CONTRACTOR,
        Component: renderWithSuspense(SingleContractorDetails)
      },
      {
        path: ROUTES.USER_MANAGEMENT.SINGLE_CONTRACTOR_USERS,
        Component: renderWithSuspense(SingleContractorUsers)
      },
      {
        path: ROUTES.USER_MANAGEMENT.SINGLE_CONTRACTOR_TENDERS,
        Component: renderWithSuspense(SingleContractorTenders),
      },
    ],
  },
];
