// types
import { IUser } from "./users.d";

export enum ECommentRelationType {
  STEEL_CLEAN_UP = "steel-clean-up",
  ADMINISTRATIVE_FEES = "administrative-fees",
  INITIATIVE_TASK = "initiative-task",
  CHANGE_ORDER = "change-order"
}


export interface IComment {
  id: number;
  createdAt: string;
  updatedAt: string;
  sender: IUser;
  content: string;
  replies: Omit<IComment, "replies">[];
  relationId: string;
  relationType: ECommentRelationType;
  additionalDetails: {
    relatedHtml: string;
    relatedUrl: string;
    relatedUrl: string;
  };
}

export interface ICommentCreate {
  content: string;
  receiverOrganizationId?: number;
  parentCommentId?: number;
}
