import { lazy } from "react";
import { RouteObject } from "react-router-dom";

// layouts
const AuthLayout = lazy(() => import("@/layouts/AuthLayout"));

// pages
const SignUp = lazy(() => import("@/pages/auth/SignUp"));
const SignIn = lazy(() => import("@/pages/auth/SignIn"));
const ResetPassword = lazy(() => import("@/pages/auth/ResetPassword"));
const CreateNewPassword = lazy(() => import("@/pages/auth/CreateNewPassword"));


// helpers
import { renderWithSuspense } from "../utils";
import { ROUTES } from "../routes.constants";

export const AUTH_ROUTES: RouteObject = {
  path: ROUTES.AUTH.ROOT,
  Component: renderWithSuspense(AuthLayout),
  children: [
    {
      path: ROUTES.AUTH.SIGN_IN,
      Component: renderWithSuspense(SignIn)
    },
    {
      path: ROUTES.AUTH.SIGN_UP,
      Component: renderWithSuspense(SignUp)
    },
    {
      path: ROUTES.AUTH.RESET_PASSWORD,
      Component: renderWithSuspense(ResetPassword)
    },
    {
      path: ROUTES.AUTH.CREATE_NEW_PASSWORD,
      Component: renderWithSuspense(CreateNewPassword)
    }
  ],
};
