import { QueryClient } from "@tanstack/react-query";

export default function getQueryClient() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        retryDelay: (attemptIndex) => Math.min(1000 * 2 * attemptIndex, 30000),
        staleTime: 3000,
        refetchOnWindowFocus: false
      }
    }
  });

  return queryClient;
}
